

































































































































































































































































































































































































































































































































































i:hover {
  cursor: pointer;
}
.title {
  text-align: center;
  margin-bottom: 15px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-items: self-start;
}
.container {
  width: 20vw;
  height: 500px;
  background: rgb(219, 219, 219);
  padding: 10px;
  .item {
    min-width: 7vw;
    height: 25px;
    margin-right: 20px;
  }
  .checkbox {
    height: 390px;
    overflow: auto;
  }
  .paging {
    text-align: center;
    margin-top: 10px;
  }
}
