i:hover {
  cursor: pointer;
}
.title {
  text-align: center;
  margin-bottom: 15px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-items: self-start;
}
.container {
  width: 20vw;
  height: 500px;
  background: #dbdbdb;
  padding: 10px;
}
.container .item {
  min-width: 7vw;
  height: 25px;
  margin-right: 20px;
}
.container .checkbox {
  height: 390px;
  overflow: auto;
}
.container .paging {
  text-align: center;
  margin-top: 10px;
}
